import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../App.css";
import Falat from "../assetes/images/Companies/falat.png";
import Jam from "../assetes/images/Companies/jam.png";
import Marun from "../assetes/images/Companies/marun-new2.png";
import Pasargad from "../assetes/images/Companies/pasargad.png";
import SORC from '../assetes/images/Companies/SORC.png';
import Zagros from "../assetes/images/Companies/zagros.png";

const CustomersComponent = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: "linear"
    };

        return (
            <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 py-10 sm:px-6 sm:py-12 lg:max-w-7xl lg:px-8">
            <div className="mainContainer">
                <Slider {...settings}>
                    <div className="container">
                        <img src={Falat} alt="Falat" />
                    </div>
                    <div className="container">
                        <img src={Jam} alt="Jam" />
                    </div>
                    <div className="container">
                        <img src={Marun} alt="Marun" />
                    </div>
                    <div className="container">
                        <img src={Pasargad} alt="Pasargad" />
                    </div>
                    <div className="container">
                        <img src={SORC} alt="SORC" />
                    </div>
                    <div className="container">
                        <img src={Zagros} alt="Zagros" />
                    </div>

                </Slider>

            </div>
            </div>
            </div>
        );
};

export default CustomersComponent;