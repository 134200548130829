import {Fragment, React } from 'react';
import {Disclosure, Menu, Transition} from '@headlessui/react'
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import Logo from '../../assetes/images/Logo.png'
import {Link} from "react-router-dom";
import { NavLink } from "react-router-dom";


const user = {
    name: 'محصولات و خدمات',
}

const userNavigation = [
    {name: 'مخازن ذخیره', description: 'مخازن پالایشگاهی (Oil and gas storage tanks)', href: '/MakhazenPage', current: true},
    {name: 'مبدل حرارتی', description: 'مبدل حرارتی لوله و پوسته (shell and tube)', href: '/MobadelPage', current: false},
    {name: 'مخازن تحت فشار', description: 'مخازن تحت فشار (pressure vessel)', href: '/PressurePage', current: false},
    {name: 'نوسازی و تعمیرات ولوها', description: 'Ball Valve, Check Valve, Globe Valve, Control valve', href: '/ValvePage', current: false},
    {name: 'ایرکولر', description: 'مبدل حرارتی هواخنک (Air cooler)', href: '/AircoolerPage', current: false},
    {name: 'قطعات صنعتی', description: 'ساخت قطعات صنعتی', href: '/IndustryPage', current: false},
]


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const NavbarComponent = () => {


    return (
        <div className="fixed top-0 w-full z-10">
            <Disclosure as="nav" className="bg-gray-800">
                {({open}) => (
                    <>
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <div className="flex h-16 justify-between">
                                <div className="flex">
                                    <div className="-ml-2 mr-2 flex items-center md:hidden">
                                        {/* Mobile menu button */}
                                        <Disclosure.Button

                                            className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                            <span className="absolute -inset-0.5"/>
                                            <span className="sr-only">Open main menu</span>
                                            {open ? (
                                                <XMarkIcon className="block h-6 w-6" aria-hidden="true"/>
                                            ) : (
                                                <Bars3Icon className="block h-6 w-6" aria-hidden="true"/>
                                            )}
                                        </Disclosure.Button>
                                    </div>
                                    <div className="flex flex-shrink-0 items-center">
                                        <Link to="/">
                                            <img
                                        className="h-10 w-auto cursor-pointer"
                                        src={Logo} alt="SteelArvinTav"/>
                                        </Link>
                                    </div>
                                    <div className="hidden md:ml-6 md:flex md:items-center md:space-x-4">

                                        <NavLink
                                            to="/"
                                            className={({ isActive }) =>
                                                `text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium 
    ${isActive ? "bg-gray-900 text-white" : ""}`
                                            }
                                        >
                                            خانه
                                        </NavLink>
                                        <Menu as="div" className="relative ml-3">
                                            <div>
                                                <Menu.Button
                                                    className="relative flex rounded-full bg-gray-800 text-sm text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium">
                                                    <span>محصولات و خدمات</span>
                                                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true"
                                                                     color="#ffffff"/>
                                                </Menu.Button>
                                            </div>

                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-200"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Item
                                                    className="absolute -left-20 z-10 mt-3 flex w-screen max-w-max -translate-x-1/2 px-4">
                                                    <div
                                                        className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 lg:max-w-3xl">
                                                        <div
                                                            className="grid grid-cols-1 gap-x-6 gap-y-1 p-4 lg:grid-cols-2">

                                                            {userNavigation.map((item) => (
                                                                <div key={item.name}
                                                                     className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-200">
                                                                    <div>
                                                                        <a href={item.href}
                                                                           className="font-semibold text-gray-900">
                                                                            {item.name}
                                                                            <span className="absolute inset-0"/>
                                                                        </a>
                                                                        <p className="mt-1 text-gray-600">{item.description}</p>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>

                                                    </div>
                                                </Menu.Item>
                                            </Transition>
                                        </Menu>

                                        <NavLink to="/News"
                                                 className={({ isActive }) =>
                                                     `text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium 
    ${isActive ? "bg-gray-900 text-white" : ""}`
                                                 } > مجله‌ما </NavLink>
                                        <NavLink to="/aboutus"
                                                 className={({ isActive }) =>
                                                     `text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium 
    ${isActive ? "bg-gray-900 text-white" : ""}`
                                                 } > درباره‌‌ما </NavLink>
                                        <NavLink to="/contactus"
                                                 className={({ isActive }) =>
                                                     `text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium 
    ${isActive ? "bg-gray-900 text-white" : ""}`
                                                 } > ارتباط با ما </NavLink>

                                    </div>
                                </div>
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                    <a
                                            href="tel:09179288808"
                                            className="relative inline-flex items-center gap-x-1.5 rounded-md bg-red-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                        >
                                            مشاوره رایگان
                                        </a>
                                    </div>
                                    <div className="hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center">

                                        {/* Profile dropdown */}

                                    </div>
                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel className="md:hidden">
                            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">

                                <Disclosure.Button
                                    as="a"
                                    href="/"
                                    className={classNames(
                                        false ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                        'block rounded-md px-3 py-2 text-base font-medium'
                                    )}
                                    aria-current={false ? 'page' : undefined}
                                >
                                    خانه
                                </Disclosure.Button>
                                <Disclosure as="nav">
                                    {({ open }) => (
                                        <>
                                            <div className="border-t border-gray-700 pb-3 pt-4">
                                                <div className="flex items-center px-5 sm:px-6">
                                                    <Disclosure.Button>
                                                        <div className="ml-3">
                                                            <div className="text-base font-medium text-white">
                                                                {user.name}
                                                            </div>
                                                        </div>
                                                    </Disclosure.Button>
                                                    <ChevronDownIcon className='h-5 w-5 text-white'/>
                                                </div>
                                                <Disclosure.Panel
                                                    className={`mt-3 space-y-1 px-2 sm:px-3 ${open ? 'block' : 'hidden'}`}>
                                                    {userNavigation.map((item) => (
                                                        <a
                                                            key={item.name}
                                                            href={item.href}
                                                            className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                                                        >
                                                            {item.name}
                                                        </a>
                                                    ))}
                                                </Disclosure.Panel>
                                            </div>
                                        </>
                                    )}
                                </Disclosure>

                                <Disclosure.Button
                                as="a"
                                href="/News"
                                className={classNames(
                                    false ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                    'block rounded-md px-3 py-2 text-base font-medium'
                                )}
                                aria-current={false ? 'page' : undefined}
                            >
                                مجله ها
                            </Disclosure.Button>
                                <Disclosure.Button
                                    as="a"
                                    href="/News"
                                    className={classNames(
                                        false ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                        'block rounded-md px-3 py-2 text-base font-medium'
                                    )}
                                    aria-current={false ? 'page' : undefined}
                                >
                                    درباره استیل‌آروین
                                </Disclosure.Button>
                                <Disclosure.Button
                                    as="a"
                                    href="/News"
                                    className={classNames(
                                        false ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                        'block rounded-md px-3 py-2 text-base font-medium'
                                    )}
                                    aria-current={false ? 'page' : undefined}
                                >
                                    تماس با ما
                                </Disclosure.Button>

                            </div>

                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>

        </div>
    );
};

export default NavbarComponent;