import React from 'react';
import texture from '../assetes/images/texture.jpg'
import Img1 from "../assetes/images/Makhazen/1.png";
import Img2 from "../assetes/images/Mobadel/2.png";
import Img3 from "../assetes/images/Aircooler/3.png";
import Img4 from "../assetes/images/Industrial/4.png";
import Img5 from "../assetes/images/Pressure/P2.png";
import Img6 from "../assetes/images/valve/V3.png";




const products = [
    {
        id: 1,
        name: 'مخازن ذخیره',
        href: '/MakhazenPage',
        imageSrc: Img1,
        imageAlt:
            'مخازن ذخیره',
    },
    {
        id: 2,
        name: 'مبدل حرارتی',
        href: '/MobadelPage',
        imageSrc: Img2,
        imageAlt:
            'مبدل حرارتی',
    },
    {
        id: 3,
        name: 'ایرکولر',
        href: '/AircoolerPage',
        imageSrc: Img3,
        imageAlt:
            'ایرکولر',
    },
    {
        id: 4,
        name: 'قطعات صنعتی',
        href: '/IndustryPage',
        imageSrc: Img4,
        imageAlt:
            'قطعات صنعتی',
    },
    {
        id: 5,
        name: 'مخازن تحت فشار',
        href: '/PressurePage',
        imageSrc: Img5,
        imageAlt:
            'مخازن تحت فشار',
    },
    {
        id: 6,
        name: 'نوسازی و تعمیرات ولوها',
        href: '/ValvePage',
        imageSrc: Img6,
        imageAlt:
            'نوسازی و تعمیرات ولوها',
    },
    // More products...
]

const ProductsComponent = () => {
    return (
        <div>
            <div className="bg-white">
                <div className="mx-auto max-w-7xl overflow-hidden px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
                    <div className="flex items-center justify-between space-x-4">
                        <h2 className="text-2xl font-bold pb-8 tracking-tight text-blue-950 sm:text-3xl animate-fade-up animate-once">محصولات ما</h2>
                        <a href="/products"
                           className="hidden text-sm font-semibold text-red-800 hover:text-red-600 sm:block">
                            مشاهده همه
                            <span aria-hidden="true"> &larr;</span>
                        </a>
                    </div>
                    <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 lg:gap-x-8">
                        {products.map((product) => (
                            <a key={product.id} href={product.href} className="group text-lg">
                                <div
                                    className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                                    <img
                                        src={product.imageSrc}
                                        alt={product.imageAlt}
                                        className="h-full w-full object-cover object-center"
                                    />
                                </div>
                                <h3 className="mt-4 font-medium text-gray-900">{product.name}</h3>
                            </a>
                        ))}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ProductsComponent;