import React from 'react';
import NotFound from '../assetes/images/404-errores.jpg'

const NotFoundPage = () => {
    return (
        <>
            {/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
      */}
            <main className="min-h-full">
                <img
                    src={NotFound}
                    alt="not found error"
                    className="absolute inset-0 -z-10 h-full w-full object-cover object-top"
                />
                <div className="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
                    <p className="mt-4 text-base text-yellow-500/70 sm:mt-6">Sorry, we couldn’t find the page you’re looking
                        for</p>
                    <div className="mt-10 flex justify-center">
                        <a href="/" className="text-3xl font-bold leading-7 text-white">
                            <span aria-hidden="true">&rarr;</span> بازگشت به خانه
                        </a>
                    </div>
                </div>
            </main>
        </>
    );
};

export default NotFoundPage;