import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { toast } from 'react-hot-toast';
import { Toaster } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import {ChevronDownIcon} from "@heroicons/react/20/solid";

const ContactFormComponent = () => {

    const form = useRef();

    // Sending Email
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                'service_aaabra8', 'template_htvclnm', form.current, 'RhhNcVJs5J4qjsptf'
            )
            .then(
                (result) => {
                    console.log(result.text);
                    form.current.reset();
                    toast.success("پیام شما با موفقیت ارسال شد");
                },
                (error) => {
                    console.log(error.text);
                    toast.error("متأسفانه پیام شما ارسال نشد!");
                }
            );
    };

    return (
        <div>
            <Toaster />
            <div className="isolate bg-orange-50 px-6 py-24 sm:py-32 lg:px-8">
                <div
                    className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
                    aria-hidden="true"
                >
                </div>
                <div className="mx-auto max-w-2xl text-center">

                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">ارسال فرم</h2>
                    <p className="mt-2 text-lg leading-8 text-gray-600">
                        ما شما را در مسیر طراحی، ساخت و اجرای پروژه همراهی می‌کنیم.
                    </p>
                </div>
                <form
                    ref={form}
                    onSubmit={sendEmail}
                    action="#" method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                        <div>
                            <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
                                نام
                            </label>
                            <div className="mt-2.5">
                                <input
                                    type="text"
                                    required
                                    name="from_name"
                                    id="first-name"
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
                                نام‌خانوادگی
                            </label>
                            <div className="mt-2.5">
                                <input
                                    type="text"
                                    required
                                    name="last-name"
                                    id="last-name"
                                    autoComplete="family-name"
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="company" className="block text-sm font-semibold leading-6 text-gray-900">
                                شرکت
                            </label>
                            <div className="mt-2.5">
                                <input
                                    type="text"
                                    required
                                    name="company"
                                    id="company"
                                    autoComplete="organization"
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                                ایمیل
                            </label>
                            <div className="mt-2.5">
                                <input
                                    type="email"
                                    required
                                    name="user_name"
                                    id="email"
                                    autoComplete="email"
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="phone-number"
                                   className="block text-sm font-semibold leading-6 text-gray-900">
                                شماره تماس
                            </label>
                            <div className="relative mt-2.5">
                                <div className="absolute inset-y-0 left-0 flex items-center">
                                    <label htmlFor="country" className="sr-only">
                                        country
                                    </label>
                                    <select
                                        id="country"
                                        name="country"
                                        required
                                        className="h-full rounded-md border-0 bg-transparent bg-none py-0 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                                    >
                                        <option>IR</option>
                                        <option>AE</option>
                                        <option>TU</option>
                                    </select>
                                    <ChevronDownIcon
                                        className="pointer-events-none absolute right-3 top-0 h-full w-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </div>
                                <input
                                    type="tel"
                                    required
                                    name="phone-number"
                                    id="phone-number"
                                    autoComplete="tel"
                                    className="block w-full rounded-md border-0 px-3.5 py-2 pl-20 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                                پیام شما
                            </label>
                            <div className="mt-2.5">
              <textarea
                  name="message"
                  required
                  id="message"
                  rows={4}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue={''}
              />
                            </div>
                        </div>

                    </div>
                    <div className="mt-10">
                        <button
                            type="submit"
                            className="block w-full rounded-md bg-red-700 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            ارسال
                        </button>
                    </div>
                </form>
            </div>

        </div>
    );
};

export default ContactFormComponent;