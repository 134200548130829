import React from 'react';
import {HomeIcon} from "@heroicons/react/20/solid";
import AboutUs3 from '../assetes/images/AboutUs/AboutUs3.png'
import AboutUs2 from '../assetes/images/AboutUs/AboutUs2.png'
import { CheckCircleIcon } from '@heroicons/react/20/solid'




const pages = [
    { name: 'درباره استیل آروین‌تاو', href: '#', current: false },
]

const AboutUsPage = () => {
    return (
        <div>
            {/* Breadcrumb */}
            <nav className="flex border-b border-gray-200 mt-16 bg-white" aria-label="Breadcrumb">
                <ol role="list" className="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8">
                    <li className="flex">
                        <div className="flex items-center">
                            <a href="/" className="text-gray-400 hover:text-gray-500">
                                <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                                <span className="sr-only">Home</span>
                            </a>
                        </div>
                    </li>
                    {pages.map((page) => (
                        <li key={page.name} className="flex">
                            <div className="flex items-center">
                                <svg
                                    className="h-full w-6 flex-shrink-0 text-gray-200 transform rotate-180"
                                    viewBox="0 0 24 44"
                                    preserveAspectRatio="none"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                                </svg>
                                <a
                                    href={page.href}
                                    className="mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                                    aria-current={page.current ? 'page' : undefined}
                                >
                                    {page.name}
                                </a>
                            </div>
                        </li>
                    ))}
                </ol>
            </nav>


            {/* Introducing */}
            <div>
                <section className="isolate overflow-hidden bg-white px-6 lg:px-8">
                    <div className="relative mx-auto max-w-2xl py-24 sm:py-24 lg:max-w-4xl">
                        <div className="absolute left-1/2 top-0 -z-10 h-[50rem] w-[90rem] -translate-x-1/2 bg-[radial-gradient(50%_100%_at_top,theme(colors.orange.200),white)] opacity-20 lg:left-36" />
                        <div className="absolute inset-y-0 right-1/2 -z-10 mr-12 w-[150vw] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-blue-600/10 ring-1 ring-blue-50 sm:mr-20 md:mr-0 lg:right-full lg:-mr-36 lg:origin-center" />
                        <figure className="grid grid-cols-1 items-center gap-x-6 gap-y-1 lg:gap-x-10">
                            <div className="relative col-span-2 lg:col-start-1 lg:row-start-2">
                                <svg
                                    viewBox="0 0 162 128"
                                    fill="none"
                                    aria-hidden="true"
                                    className="absolute -top-12 left-0 -z-10 h-32 stroke-gray-900/10"
                                >
                                    <path
                                        id="b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb"
                                        d="M65.5697 118.507L65.8918 118.89C68.9503 116.314 71.367 113.253 73.1386 109.71C74.9162 106.155 75.8027 102.28 75.8027 98.0919C75.8027 94.237 75.16 90.6155 73.8708 87.2314C72.5851 83.8565 70.8137 80.9533 68.553 78.5292C66.4529 76.1079 63.9476 74.2482 61.0407 72.9536C58.2795 71.4949 55.276 70.767 52.0386 70.767C48.9935 70.767 46.4686 71.1668 44.4872 71.9924L44.4799 71.9955L44.4726 71.9988C42.7101 72.7999 41.1035 73.6831 39.6544 74.6492C38.2407 75.5916 36.8279 76.455 35.4159 77.2394L35.4047 77.2457L35.3938 77.2525C34.2318 77.9787 32.6713 78.3634 30.6736 78.3634C29.0405 78.3634 27.5131 77.2868 26.1274 74.8257C24.7483 72.2185 24.0519 69.2166 24.0519 65.8071C24.0519 60.0311 25.3782 54.4081 28.0373 48.9335C30.703 43.4454 34.3114 38.345 38.8667 33.6325C43.5812 28.761 49.0045 24.5159 55.1389 20.8979C60.1667 18.0071 65.4966 15.6179 71.1291 13.7305C73.8626 12.8145 75.8027 10.2968 75.8027 7.38572C75.8027 3.6497 72.6341 0.62247 68.8814 1.1527C61.1635 2.2432 53.7398 4.41426 46.6119 7.66522C37.5369 11.6459 29.5729 17.0612 22.7236 23.9105C16.0322 30.6019 10.618 38.4859 6.47981 47.558L6.47976 47.558L6.47682 47.5647C2.4901 56.6544 0.5 66.6148 0.5 77.4391C0.5 84.2996 1.61702 90.7679 3.85425 96.8404L3.8558 96.8445C6.08991 102.749 9.12394 108.02 12.959 112.654L12.959 112.654L12.9646 112.661C16.8027 117.138 21.2829 120.739 26.4034 123.459L26.4033 123.459L26.4144 123.465C31.5505 126.033 37.0873 127.316 43.0178 127.316C47.5035 127.316 51.6783 126.595 55.5376 125.148L55.5376 125.148L55.5477 125.144C59.5516 123.542 63.0052 121.456 65.9019 118.881L65.5697 118.507Z"
                                    />
                                    <use href="#b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb" x={86} />
                                </svg>
                                <blockquote className="text-xl text-justify font-normal leading-8 text-gray-900 sm:text-2xl sm:leading-9">
                                    <p>
                                        شرکت استیل آروین تاو  از سال 1386 با بهره گیری از متخصصین مجرب دانشگاه‌های معتبر داخلی و خارجی در علوم فنی و مهندسی در رشته های مهندسی مکانیک، مهندسی شیمی و سایر علوم مرتبط و همچنین با در اختیار داشتن فضای تولیدی و ابزارآلات و ماشین آلات مورد نیاز در عرصه طراحی و ساخت تجهیزات ثابت واحدهای فرآیندی و یوتیلیتی صنایع نفت و گاز و پتروشیمی و صنایع دیگر فعالیت دارد.
                                        این شرکت بر آن است با تکیه بر تجارب سالیان متمادی خود که بصورت کاملا تخصصی در زمینه‌های طراحی،ساخت، مهندسی معکوس ،عیب یابی و بهینه سازی تجهیزات فرآیندی است گامی موثر در جهت رفع نیاز‌های صنایع مذکور و پیشبرد اهداف سازمانی خود بردارد. در این راستا اهم فعالیت‌ها، توانایی‌ها و پروژه‌های صورت گرفته به اختصار به حضور معرفی میگردد.
                                    </p>
                                </blockquote>
                            </div>
                            <div className="col-start-1 w-16 lg:row-span-4 lg:w-72">

                            </div>
                            <figcaption className="text-base lg:col-start-1 lg:row-start-3">
                                <div className="font-semibold text-gray-900"></div>
                                <div className="mt-1 text-gray-500"></div>
                            </figcaption>
                        </figure>
                    </div>
                </section>
            </div>


            {/* Picture Container */}
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                {/* We've used 3xl here, but feel free to try other max-widths based on your needs */}
                <div className="mx-auto max-w-2xl">
                    <img src={AboutUs2}/>
                </div>
            </div>


            {/* Content Section */}
            <div className="bg-white px-6 py-32 lg:px-8">
                <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                    <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">ما چه می‌کنیم؟</h1>
                    <p className="mt-6 text-xl leading-8">
                        شرکت استیل آروین تاو فعالیت خود را در چهار بخش اصلی متمرکز کرده است:
                        <br/><br/>
                        ۱. طراحی، مهندسی و مشاوره
                        <br/>
                        ۲. ساخت تجهیزات ثابت فرآیندی
                        <br/>
                        ۳. نوسازی، تعمیرات و ساخت انواع ولو ها و سایر قطعات صنعتی
                        <br/>
                        ۴. تامین مواد اولیه و تجهیزات از منابع معتبر داخلی و خارجی
                        <br/><br/>
                        در ادامه به اختصار به بیان هر کدام از بخش های مذکور می‌پردازیم:
                    </p>
                    <div className="mt-10 max-w-2xl">
                        <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">۱. طراحی، مهندسی و مشاوره</h2>
                        <ul role="list" className="mt-8 max-w-xl space-y-8 text-gray-600">
                            <li className="flex gap-x-3">
                                <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-orange-600" aria-hidden="true" />
                                <span>
                                    طراحی حرارتی و مکانیکی، تدوین تکنولوژي ساخت و مدیریت ساخت انواع تجهیزات ثابت انتقال حرارت، انواع مبدل های حرارتی پوسته و لوله، ایرکولر، راکتور، کانورتور، ریجنراتور و انواع تجهیزات تحت فشار فوق سنگین، سیستم هاي سیکل تبرید صنعتی، کوره هاي صنایع فرایندي بر اساس استانداردهای بین المللی و با استفاده از نرم افزارهای حرفه ای و مرتبط مانند:
 <br/>
                                    PV-Elite, HTRI, E-Tank, Nozzle pro, SAP, Solid work, Auto cad, Catia, Hysys,

Aspen B-Jac, Tekla Structure,…
              </span>
                            </li>
                            <li className="flex gap-x-3">
                                <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-orange-600" aria-hidden="true" />
                                <span>
                طراحی و توسعه سیستم های هیبریدی برج های خنک کننده صنعتی با نرم افزار های اختصاصی با قابلیت های منحصر به فرد و صرفه جویی و کاهش مصرف آب تا 95%( با توجه به شرایط آب و هوایی مناطق مختلف)
              </span>
                            </li>
                            <li className="flex gap-x-3">
                                <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-orange-600" aria-hidden="true" />
                                <span>
                طراحی انواع مکانیزم های صنعتی و سیستم های کنترلی
              </span>
                            </li>
                            <li className="flex gap-x-3">
                                <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-orange-600" aria-hidden="true" />
                                <span>
                طراحی و مهندسی معکوس انواع اجکتور های مایع، بخار و انواع سایلنسرهای اگزوز، ونت و کمپرسور
              </span>
                            </li>
                            <li className="flex gap-x-3">
                                <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-orange-600" aria-hidden="true" />
                                <span>
                مدلسازی و تحلیل دینامیک و ارتعاشات انواع سیستم های مهندسی
              </span>
                            </li>
                            <li className="flex gap-x-3">
                                <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-orange-600" aria-hidden="true" />
                                <span>
                تحلیل تنش و خستگی انواع سازه ها و مکانیزم ها و تحلیل سیستم های تولیدی فرآیندی و رفتار سنجی عملکرد تجهیزات ثابت و دوار و ریشه یابی عیوب طراحی یا عملیاتی و ارائه راهکارهای رفع مشکل(ROOT CAUSE ANALYSIS)
              </span>
                            </li>
                            <li className="flex gap-x-3">
                                <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-orange-600" aria-hidden="true" />
                                <span>
                تحلیل های علمی-کاربردی به کمک نرم افزارهای  (ANSYS, ABAQUS,…)و ارائه راه حل برای پدیده های مخرب در ماشین آلات وتجهیزات فرآیندی شامل: مقاومت مکانیکی، فرسایش، خزش، خستگی، ارتعاش، شکست قطعات و پدیده های ترکیبی فرآیندی - مکانیکی
              </span>
                            </li>
                            <li className="flex gap-x-3">
                                <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-orange-600" aria-hidden="true" />
                                <span>
تحلیل و ریشه یابی و ارائه راهکارهای اصلاحی تضمینی برای رفع مشکلات کاهش تولید در سیستم های فرآیندی مکانیکی در صنایع پتروشیمی و پالایشگاهی              </span>
                            </li>

                        </ul>

                        <h4 className="mt-4 text-l font-bold tracking-tight text-gray-900 sm:text-xl">خدمات مشاوره حرفه ای در حوزه متالوژی کاربردی شامل:</h4>
                        <p className="mt-6 text-xl leading-8">
                            ۱. تحلیل انواع خوردگی ها و فرسایش در ارتباط با آب دریا و سیالات و گازهای فرآیندی و ارائه راه حل های اصلاحی
                            <br/>
                            ۲.انتخاب و بهینه سازی آلیاژ قطعات برای انواع کاربردها
                            <br/>
                            ۳. انواع عملیات حرارتی
                            <br/>
                            ۴.متالوژی جوش
                            <br/>
                            ۵. تدوین روش های فرم دهی گرم و جوشکاری آلیاژهای خاص و سوپر آلیاژها
                            <br/>
                            ۶. طراحی ،تدوین تکنولوژی و مدیریت راه اندازی سیستم های مکانیکی صنایع نفت و گاز و پتروشیمی
                            <br/><br/>
                            <span className="text-blue-600">
                                 به پیوست فایل های نمونه طراحی های مکانیکی و حرارتی تعدادی از پروژ های تجهیزات ثابت و همچنین نمونه طرح سیستم هیبریدی به پیوست ارائه میگردد
                            </span>
                        </p>

                        <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">۲. ساخت و مهندسی تجهیزات ثابت فرآیندی</h2>

                        <figure className="mt-10 border-r border-orange-800 pr-9">
                            <blockquote className="font-semibold text-gray-900">
                                <p>
                                    شرکت استیل آروین تاو با با تکیه بر تجربه و تخصص اعضا، کارکنان، مشاوران و همچنین امکانات و تجهیزات ساخت توانایی قابل توجهی در زمینه مهندسی، طراحی، ساخت و تعمیرات تجهیزات ثابت نظیر انواع مبدل های حرارتی پوسته و لوله، ایرکولر، مخازن ذخیره، مخازن تحت فشار، کوره های صنعتی، کانوکشن کویل، تاور، درایر، انواع اجکتور، سایلنسر و ... برای صنایع مختلف کشور، خصوصا صنایع نفت، گاز و پتروشیمی را دارد که در ذیل مطلب به طور خلاصه به برخی از پروژه های صورت گرفته اشاره شده است.
                                </p>
                            </blockquote>

                        </figure>

                    </div>
                    <figure className="mt-16">
                        <img
                            className="aspect-video rounded-xl bg-gray-50 object-cover"
                            src={AboutUs3}
                            alt=""
                        />

                    </figure>
                    <div className="mt-16 max-w-2xl">
                        <h2 className="text-2xl font-bold tracking-tight text-gray-900">۳. نوسازی، تعمیرات و ساخت انواع ولو ها و سایر قطعات صنعتی</h2>
                        <p className="mt-6">
                            شرکت استیل آروین تاو با بهره گیری از کادر فنی و مهندسی مجرب و ماشین آلات CNCدر زمینه ساخت، تعمیرات و نوسازی انواع شیر آلات صنعتی (Ball Valve, Check Valve, Globe Valve, Control valve, …)و همچنین در زمینه طراحی، ساخت و مهندسی معکوس قطعات دقیق و حساس صنعتی به کمک نرم افزارهای تخصصی CAD/CAM/CAE

                            فعایت دارد و توانسته است در این زمینه نیز رزومه و سابقه درخشانی ایجاد کند که به اختصار عناوین و تصاویر تعدادی از پروژه ها و نمونه کارها ارائه میگردد.
                        </p>
                    </div>
                    <div className="mt-16 max-w-2xl">
                        <h2 className="text-2xl font-bold tracking-tight text-gray-900">۴. تامین مواد اولیه و تجهیزات از منابع معتبر داخلی و خارجی</h2>
                        <p className="mt-6">
                            واحد بازرگانی خارجی و داخلی این شرکت به واسطه وجود متخصصین مجرب در حوزه بازرگانی، به منظور تامین نیاز های خود و صنایع نفت، گاز و پتروشیمی، منابع مختلف داخلی و خارجی معتبر را شناسایی و ارزیابی کرده و قادر به تهیه و تامین متریال مصرفی از منابع مذکور از طریق گذرگاههای رسمی کشور است.
                        </p>
                    </div>
                </div>
            </div>

        </div>

    );
};

export default AboutUsPage;