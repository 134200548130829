import React from 'react';
import SalesMeeting from "../assetes/images/AboutUs/SalesMeeting.png";
import Sakht from "../assetes/images/AboutUs/Sakht.png";
import Nasb from "../assetes/images/Makhazen/Nasb.png";


const posts = [
    {
        id: 1,
        title: 'دریافت اطلاعات از مشتری',
        description:
            'دریافت مشخصات پروژه، بررسی فنی، طراحی اولیه و ارائه قرارداد',
        imageUrl:
            SalesMeeting,

    },
    {
        id: 1,
        title: 'طراحی، مهندسی و ساخت',
        description:
            'طراحی نقشه‌ها، ساخت تجهیزات، کنترل کیفیت',
        imageUrl:
        Sakht,

    },
    {
        id: 1,
        title: 'نصب، راه‌اندازی و پشتیبانی',
        description:
            'حمل، نصب و راه‌اندازی، پشتیبانی و خدمات پس از فروش',
        imageUrl:
            Nasb,
    },
    // More posts...
]

const ProcessComponent = () => {
    return (
        <div>
            <div className="bg-blue-50 py-24 sm:py-24">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:max-w-4xl">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">فرآیند کار</h2>

                        <div className="mt-16 space-y-20 lg:mt-20 lg:space-y-20">
                            {posts.map((post) => (
                                <article key={post.id} className="relative isolate flex flex-col gap-8 lg:flex-row">
                                    <div className="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0">
                                        <img
                                            src={post.imageUrl}
                                            alt=""
                                            className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover"
                                        />
                                        <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                                    </div>
                                    <div>

                                        <div className="relative max-w-xl">
                                            <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900">
                                                <a href={post.href}>
                                                    <span className="absolute inset-0" />
                                                    {post.title}
                                                </a>
                                            </h3>
                                            <p className="mt-5 text-sm leading-6 text-gray-600">{post.description}</p>
                                        </div>
                                        <div className="mt-6 flex border-t border-gray-900/5 pt-6">

                                        </div>
                                    </div>
                                </article>
                            ))}
                        </div>
                    </div>
                    <div className="mt-10 flex">
                        <a
                            href="tel:09179288808"
                            className="rounded-md bg-red-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            مشاوره رایگان<span aria-hidden="true">&larr;</span>
                        </a>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ProcessComponent;