import React from 'react';
import AboutUs from "../assetes/images/AboutUs/AboutUs.png";

const AboutUsComponent = () => {
    return (
        <div>
            <div className="flow-root bg-white pb-10 sm:pb-18">
                <div className="-mt-9">
                    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                        <div className="mx-auto flex max-w-2xl shadow-xl flex-col gap-16 bg-gray-200 bg-opacity-65 px-6 py-16 ring-2 ring-gray-900/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
                            <img
                                className="h-96 w-full flex-none rounded-2xl object-cover shadow-xl lg:aspect-square lg:h-auto lg:max-w-sm"
                                src={AboutUs}
                                alt="AboutUs"
                            />
                            <div className="w-full flex-auto">
                                <h2 className="text-3xl font-bold tracking-tight text-blue-950 sm:text-4xl animate-fade-left animate-once">درباره استیل آروین‌تاو</h2>
                                <p className="mt-6 text-lg leading-8 text-gray-900 animate-fade-up animate-once">
                                    گروه تولیدی و صنعتی استیل آروین‌تاو، با هدف ارتقای سطح کیفی و استانداردهای اجرایی در زمینه طراحی، ساخت، نصب و راه‌اندازی ماشین‌آلات صنایع غذایی و شیمیایی، فعالیت مستمر و ارزش آفرین خود را پایه‌گذاری کرد. در همین راستا این شرکت آمادگی خود را جهت مشاوره در اجرا، طراحی و ساخت انواع تجهیزات و اجرای انواع پروژه های صنعتی اعلام میدارد.
                                    امید آنکه هر روز با تلاش نیروهای متعهد و متخصص و خودباور با قدرت به فعل ما میتوانیم معنا ببخشیم.
                                </p>

                                <div className="mt-10 flex">
                                    <a href="/aboutus" className="text-sm font-semibold leading-6 text-red-800 hover:text-red-600 animate-fade-left animate-once">
                                        درباره ما بیشتر بدانید
                                        <span aria-hidden="true">&larr;</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUsComponent;