import mz from '../assetes/images/services/MZ.png';
import air from '../assetes/images/services/air.png';
import mh from '../assetes/images/services/mh.png';
import pv from '../assetes/images/services/pv.png';
import t from '../assetes/images/services/t.png';
import valve from '../assetes/images/services/valve.png';




export const data = [
    {
      id: 1,
      img: valve,
    },
    {
        id: 2,
        img: t,
    },
    {
        id: 3,
        img: mz,
    },
    {
        id: 4,
        img: mh,
    },
    {
        id: 5,
        img: pv,
    },
    {
        id: 6,
        img: air,
    },
]