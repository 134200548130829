import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { toast } from 'react-hot-toast';
import { Toaster } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import Logo from '../components/ui/Logo.png';



const ContactUsComponent = () => {

    const form = useRef();

    // Sending Email
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                'service_aaabra8', 'template_htvclnm', form.current, 'RhhNcVJs5J4qjsptf'
            )
            .then(
                (result) => {
                    console.log(result.text);
                    form.current.reset();
                    toast.success("پیام شما با موفقیت ارسال شد");
                },
                (error) => {
                    console.log(error.text);
                    toast.error("متأسفانه پیام شما ارسال نشد!");
                }
            );
    };

    return (
        <div>
            <Toaster />
            <div className="relative isolate bg-gradient-to-t from-blue-300 to-orange-200 px-6 py-24 sm:py-32 lg:px-8">
                <svg
                    className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                            width={200}
                            height={200}
                            x="50%"
                            y={-64}
                            patternUnits="userSpaceOnUse"
                        >
                            <path d="M100 200V.5M.5 .5H200" fill="none" />
                        </pattern>
                    </defs>
                    <svg x="50%" y={-64} className="overflow-visible fill-gray-50">
                        <path
                            d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M299.5 800h201v201h-201Z"
                            strokeWidth={0}
                        />
                    </svg>
                    <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
                </svg>
                <div className="mx-auto max-w-xl lg:max-w-4xl">
                    <h2 className="text-4xl font-bold tracking-tight text-gray-900">بیایید در مورد پروژه شما صحبت کنیم!</h2>
                    <p className="mt-2 text-lg leading-8 text-gray-600">
                        ما شما را در مسیر طراحی، ساخت و اجرای پروژه همراهی می‌کنیم.
                    </p>
                    <div className="mt-16 flex flex-col gap-16 sm:gap-y-20 lg:flex-row">
                        <form ref={form}
                              onSubmit={sendEmail}
                              action="#" method="POST" className="lg:flex-auto">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                                <div>
                                    <label htmlFor="name" className="block text-sm font-semibold leading-6 text-gray-900">
                                        نام
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            type="text"
                                            required
                                            name="from_name"
                                            id="first-name"
                                            autoComplete="given-name"
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
                                        نام‌خانوادگی
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            type="text"
                                            required
                                            name="last-name"
                                            id="last-name"
                                            autoComplete="family-name"
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                                        ایمیل
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            type="email"
                                            required
                                            name="user_name"
                                            id="email"
                                            autoComplete="email"
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-900">
                                        شماره تماس
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            type="tel"
                                            required
                                            name="phone-number"
                                            id="phone-number"
                                            autoComplete="tel"
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>


                                <div className="sm:col-span-2">
                                    <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                                        پیام شما
                                    </label>
                                    <div className="mt-2.5">
                  <textarea
                      id="message"
                      required
                      name="message"
                      rows={4}
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      defaultValue={''}
                  />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-10">
                                <button
                                    type="submit"
                                    className="block w-full rounded-md bg-orange-700 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    ارسال پیام
                                </button>
                            </div>

                        </form>
                        <div className="lg:mt-6 lg:w-80 lg:flex-none">
                            <img className="h-14 w-auto"
                                 src={Logo} alt="" />
                            <figure className="mt-10">
                                <blockquote className="text-lg font-semibold leading-8 text-gray-900">
                                    <p>
                                        “برای مشاوره تخصصی در بخش‌های طراحی و مهندسی،
                                        ساخت تجهیزات ثابت‌فرآیندی،
                                        نوسازی، تعمیرات و ساخت انواع ولو ها و سایر قطعات صنعتی،
                                        تامین مواد اولیه و تجهیزات از منابع معتبر داخلی و خارجی
                                        با ما در ارتباط باشید.”
                                    </p>
                                </blockquote>

                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUsComponent;