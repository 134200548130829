import React from 'react';
import {HomeIcon} from "@heroicons/react/20/solid";
import Img1 from "../assetes/images/Makhazen/1.png";
import Img2 from "../assetes/images/Mobadel/2.png";
import Img3 from "../assetes/images/Aircooler/3.png";
import Avatar from "../assetes/images/Avatar.png";
import Nasb from "../assetes/images/Makhazen/Nasb.png";
import Gallery8 from "../assetes/images/Aircooler/Gallery8.png";
import Gallery9 from "../assetes/images/Mobadel/Gallery9.png";



const pages = [
    { name: 'مجله ما', href: '/News', current: false },
]

const posts = [
    {
        id: 1,
        title: 'مخازن ذخیره',
        href: '/MakhazenArticle',
        description:
            'این مخازن برای ذخیره سازی مواد نفتی و یا شیمیایی که در دمای محیط به صورت مایع هستند مورد استفاده قرار می‌گیرند. موادی همچون نفت خام، بنزین، گازوییل و برش های سنگین نفت خام در این مخازن ذخیره سازی می‌شوند.',
        imageSrc: Img1,
        date: 'Mar 16, 2020',
        datetime: '2020-03-16',
        category: { title: 'صنعت' },
        author: {
            name: 'امید ایزدی',
            imageSrc: Avatar,
        },
    },
    {
        id: 1,
        title: 'مبدل حرارتی',
        href: '/MobadelArticle',
        description:
            'مبدل های حرارتی پوسته و لوله یکی از پرکاربردترین انواع مبدل های حرارتی در صنایع مختلف هستند. این مبدل ها از یک مجموعه لوله ها و یک پوسته تشکیل شده اند. ',
        imageSrc: Img2,
        date: 'Mar 16, 2020',
        datetime: '2020-03-16',
        category: { title: 'صنعت' },
        author: {
            name: 'امید ایزدی',
            imageSrc: Avatar,
        },
    },
    {
        id: 1,
        title: 'ایرکولر',
        href: '/AircoolerArticle',
        description:
            'ایر کولر صنعتی  یا مبدل هوا خنک در اکثر پالایشگاه‌ها و پتروشیمی ها کارایی دارد که وظیفه اصلی ایر کولر کاهش دمای سیالات است. ',
        imageSrc: Img3,
        date: 'Mar 16, 2020',
        datetime: '2020-03-16',
        category: { title: 'صنعت' },
        author: {
            name: 'امید ایزدی',
            imageSrc: Avatar,
        },
    },
    // More posts...
]

const NewsPage = () => {
    return (
        <div>
            {/* Breadcrumb */}
            <nav className="flex border-b border-gray-200 mt-16 bg-white" aria-label="Breadcrumb">
                <ol role="list" className="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8">
                    <li className="flex">
                        <div className="flex items-center">
                            <a href="/" className="text-gray-400 hover:text-gray-500">
                                <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                                <span className="sr-only">Home</span>
                            </a>
                        </div>
                    </li>
                    {pages.map((page) => (
                        <li key={page.name} className="flex">
                            <div className="flex items-center">
                                <svg
                                    className="h-full w-6 flex-shrink-0 text-gray-200 transform rotate-180"
                                    viewBox="0 0 24 44"
                                    preserveAspectRatio="none"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                                </svg>
                                <a
                                    href={page.href}
                                    className="mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                                    aria-current={page.current ? 'page' : undefined}
                                >
                                    {page.name}
                                </a>
                            </div>
                        </li>
                    ))}
                </ol>
            </nav>


            {/* News */}
            <div className="bg-gray-50">
                <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
                    <div className="sm:flex sm:items-baseline sm:justify-between">
                        <h2 className="text-2xl font-bold tracking-tight text-gray-900">جدیدترین خبرها</h2>
                    </div>

                    <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-6 lg:gap-8">
                        <div className="group aspect-h-1 aspect-w-2 overflow-hidden rounded-lg sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2">
                            <img
                                src={Nasb}
                                alt="مخازن ذخیره"
                                className="object-cover object-center group-hover:opacity-75"
                            />
                            <div aria-hidden="true" className="bg-gradient-to-b from-transparent to-black opacity-50" />
                            <div className="flex items-end p-6">
                                <div>
                                    <h3 className="font-semibold text-white">
                                        <a href="/MakhazenPage">
                                            <span className="absolute inset-0" />
                                            مخازن ذخیره
                                        </a>
                                    </h3>
                                    <p aria-hidden="true" className="mt-1 text-sm text-white">
                                       بخوانید
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="group aspect-h-1 aspect-w-2 overflow-hidden rounded-lg sm:aspect-none sm:relative sm:h-full">
                            <img
                                src={Gallery9}
                                alt="مبدل حرارتی"
                                className="object-cover object-center group-hover:opacity-75 sm:absolute sm:inset-0 sm:h-full sm:w-full"
                            />
                            <div
                                aria-hidden="true"
                                className="bg-gradient-to-b from-transparent to-black opacity-50 sm:absolute sm:inset-0"
                            />
                            <div className="flex items-end p-6 sm:absolute sm:inset-0">
                                <div>
                                    <h3 className="font-semibold text-white">
                                        <a href="/MobadelPage">
                                            <span className="absolute inset-0" />
                                            مبدل حرارتی
                                        </a>
                                    </h3>
                                    <p aria-hidden="true" className="mt-1 text-sm text-white">
                                       بخوانید
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="group aspect-h-1 aspect-w-2 overflow-hidden rounded-lg sm:aspect-none sm:relative sm:h-full">
                            <img
                                src={Gallery8}
                                alt="ایرکولر"
                                className="object-cover object-center group-hover:opacity-75 sm:absolute sm:inset-0 sm:h-full sm:w-full"
                            />
                            <div
                                aria-hidden="true"
                                className="bg-gradient-to-b from-transparent to-black opacity-50 sm:absolute sm:inset-0"
                            />
                            <div className="flex items-end p-6 sm:absolute sm:inset-0">
                                <div>
                                    <h3 className="font-semibold text-white">
                                        <a href="/AircoolerPage">
                                            <span className="absolute inset-0" />
                                            ایرکولر
                                        </a>
                                    </h3>
                                    <p aria-hidden="true" className="mt-1 text-sm text-white">
                                        بخوانید
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 sm:hidden">
                        <a href="#" className="block text-sm font-semibold text-indigo-600 hover:text-indigo-500">
                            Browse all categories
                            <span aria-hidden="true"> &rarr;</span>
                        </a>
                    </div>
                </div>
            </div>



            {/* Articles */}
            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl text-center">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">مقالات تخصصی</h2>
                        <p className="mt-2 text-lg leading-8 text-gray-600">

                        </p>
                    </div>
                    <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        {posts.map((post) => (
                            <article key={post.id} className="flex flex-col items-start justify-between">
                                <div className="relative w-full">
                                    <img
                                        src={post.imageSrc}
                                        alt=""
                                        className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                                    />
                                    <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                                </div>
                                <div className="max-w-xl">
                                    <div className="mt-8 flex items-center gap-x-4 text-xs">
                                        <time dateTime={post.datetime} className="text-gray-500">
                                            {post.date}
                                        </time>
                                        <a
                                            href={post.category}
                                            className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-700"
                                        >
                                            {post.category.title}
                                        </a>
                                    </div>
                                    <div className="group relative">
                                        <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                                            <a href={post.href}>
                                                <span className="absolute inset-0" />
                                                {post.title}
                                            </a>
                                        </h3>
                                        <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{post.description}</p>
                                    </div>
                                    <div className="relative mt-8 flex items-center gap-x-4">
                                        <img src={post.author.imageSrc} alt="" className="h-10 w-10 rounded-full bg-gray-100" />
                                        <div className="text-sm leading-6">
                                            <p className="font-semibold text-gray-900">
                                                <a href={post.author.href}>
                                                    <span className="absolute inset-0" />
                                                    {post.author.name}
                                                </a>
                                            </p>
                                            <p className="text-gray-600">{post.author.role}</p>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        ))}
                    </div>
                </div>
            </div>

            {/* Subscribe */}
            <div className="bg-white py-16 sm:py-24 lg:py-32">
                <div className="mx-auto grid max-w-7xl grid-cols-1 gap-10 px-6 lg:grid-cols-12 lg:gap-8 lg:px-8">
                    <div className="max-w-xl text-xl font-bold tracking-tight text-gray-900 sm:text-2xl lg:col-span-7">
                        <h2 className="inline sm:block lg:inline xl:block">برای اطلاع از دنیای صنعت و<br/> ساخت و تولید همین حالا عضو شوید.</h2>{' '}
                    </div>
                    <form className="w-full max-w-md lg:col-span-5 lg:pt-2">
                        <div className="flex gap-x-4">
                            <label htmlFor="email-address" className="sr-only">
                                Email address
                            </label>
                            <input
                                id="email-address"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                className="min-w-0 flex-auto rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="ایمیل خود را وارد کنید"
                            />
                            <button
                                type="submit"
                                className="flex-none rounded-md bg-red-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                عضویت
                            </button>
                        </div>

                    </form>
                </div>
            </div>
            
        </div>
    );
};

export default NewsPage;