import React from 'react';
import { TypeAnimation } from 'react-type-animation';
import Banner from '../assetes/images/Banner.png'
import AboutUsComponent from "../components/AboutUs.component";
import ProductsComponent from "../components/Products.component";
import ServicesComponent from "../components/Services.component";
import ProcessComponent from "../components/Process.component";
import CustomersComponent from "../components/Customers.component";
import ContactUsComponent from "../components/ContactUs.component";




const HomePage = () => {

    return (
        <div>
            <div className="bg-white" style={{ backgroundImage: "url(" + Banner +")" }}>
                <div className="px-6 py-24 z-0 sm:px-6 sm:py-32 lg:px-8">
                    <div className="mx-auto max-w-2xl text-center">
                        <div className="mt-10 text-gray-800 font-bold">
                            <TypeAnimation
                                sequence={[
                                    'استیل آروین‌تاو تخصص بومی',
                                    1000,
                                    'استیل آروین‌تاو تکنولوژی جهانی',
                                    1000,
                                    'استیل آروین‌تاو صنعت جهانی',
                                    1000,
                                    'استیل آروین‌تاو طراحی صنعتی',
                                    1000
                                ]}
                                wrapper="span"
                                speed={50}
                                style={{ fontSize: '1.5em', display: 'inline-block' }}
                                repeat={Infinity}
                            />
                        </div>
                        <p className="mx-auto opacity-0 mt-6 max-w-xl text-lg leading-8 text-gray-600">
                            Incididunt sint fugiat pariatur cupidatat consectetur sit cillum anim id veniam aliqua proident excepteur
                            commodo do ea.
                        </p>
                        <div className="mt-10 opacity-0 flex items-center justify-center gap-x-6">
                            <a
                                href="#"
                                className="rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                               شروع کنیم
                            </a>

                        </div>
                    </div>
                </div>
            </div>


            {/* About Us */}
            <AboutUsComponent  />

            {/* Services */}
            <ServicesComponent />

            {/* Products */}
            <ProductsComponent />

            {/* Process */}
            <ProcessComponent />

            {/* Customers */}
            <CustomersComponent />

            {/* ContactUs */}
            <ContactUsComponent />

        </div>



    );
};


export default HomePage;