import React, {useState} from 'react';
import {BsChevronCompactLeft, BsChevronCompactRight} from "react-icons/bs";
import { RxDotFilled } from 'react-icons/rx';
import { HomeIcon } from '@heroicons/react/20/solid';
import Img1 from "../../assetes/images/Aircooler/3.png";
import Img2 from "../../assetes/images/Aircooler/Gallery8.png";
import Img3 from "../../assetes/images/Aircooler/Gallery3.png";
import Img4 from "../../assetes/images/Aircooler/Gallery5.png";
import Img5 from "../../assetes/images/Aircooler/Gallery1.png";




const pages = [
    { name: 'محصولات', href: '/products', current: false },
    { name: 'ایرکولر', href: '/AircoolerPage', current: false },
]

const AircoolerPage = () => {

    const slides = [
        {
         img: Img1,
        },
        {
            img: Img2,
        },
        {
            img: Img3,
        },
        {
            img: Img4,
        },
        {
            img: Img5,
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    const prevSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const nextSlide = () => {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
    };

    return (
        <div>
            {/* Breadcrumb */}
            <nav className="flex border-b border-gray-200 mt-16 bg-white" aria-label="Breadcrumb">
                <ol role="list" className="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8">
                    <li className="flex">
                        <div className="flex items-center">
                            <a href="/" className="text-gray-400 hover:text-gray-500">
                                <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true"/>
                                <span className="sr-only">Home</span>
                            </a>
                        </div>
                    </li>
                    {pages.map((page) => (
                        <li key={page.name} className="flex">
                            <div className="flex items-center">
                                <svg
                                    className="h-full w-6 flex-shrink-0 text-gray-200 transform rotate-180"
                                    viewBox="0 0 24 44"
                                    preserveAspectRatio="none"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z"/>
                                </svg>
                                <a
                                    href={page.href}
                                    className="mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                                    aria-current={page.current ? 'page' : undefined}
                                >
                                    {page.name}
                                </a>
                            </div>
                        </li>
                    ))}
                </ol>
            </nav>

            {/*Article */}
            <div className='max-w-[1000px] h-[600px] w-full m-auto py-16 px-4 relative group'>
                <div
                    style={{backgroundImage: `url(${slides[currentIndex].img})`}}
                    className='w-full h-full rounded-2xl bg-center bg-cover duration-500'
                ></div>
                {/* Left Arrow */}
                <div
                    className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
                    <BsChevronCompactLeft onClick={prevSlide} size={30}/>
                </div>
                {/* Right Arrow */}
                <div
                    className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
                    <BsChevronCompactRight onClick={nextSlide} size={30}/>
                </div>
                <div className='flex top-4 justify-center py-2'>
                    {slides.map((slide, slideIndex) => (
                        <div
                            key={slideIndex}
                            onClick={() => goToSlide(slideIndex)}
                            className='text-2xl cursor-pointer'
                        >
                            <RxDotFilled/>
                        </div>
                    ))}
                </div>
            </div>


            <div className="flow-root bg-white pb-10 sm:pb-18">
                <div className="">
                    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                        <div
                            className="mx-auto flex max-w-2xl shadow-xl flex-col gap-16 bg-gray-200 bg-opacity-65 px-6 py-16 ring-2 ring-gray-900/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">

                            <div className="mx-auto max-w-3xl text-center">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">ایرکولر</h2>
                                <p className="mt-4 text-gray-500">
                                    مبدل حرارتی هواخنک (Air cooler)
                                </p>
                                <br/>
                                <p className="text-right text-justify">
                                    ایر کولر صنعتی یا مبدل هوا خنک در اکثر پالایشگاه‌ها و پتروشیمی ها کارایی دارد که
                                    وظیفه اصلی ایر کولر کاهش دمای سیالات است. کولر هوایی نوعی مبدل حرارتی محسوب شده و
                                    توسط آن گرمای اضافی سیال به هوا انتقال می‌یابد تا هوای محیط درحد استاندارد کاهش پیدا
                                    کند. سیستم کاری ایر کولر صنعتی طبق تبادل گرمایشی طراحی می‌گردد و دمای آبگرم درون
                                    کویل را تا نزدیک دمای محیط کم می‌نماید.

                                </p>
                                <p className="text-right text-justify">
                                    کولرهای هوایی برای مناطقی که بحران کمبود آب دارند یا بر اساس حداکثر دمای فضای مدنظر
                                    میتوان به دمای خروجی فرآیند تعیین شده دست یافت بکار برده می‌شوند. همچنین نحوه عملکرد
                                    ایر کولر با برج خنک کننده نیز تقریبا شباهت داشته ولیکن از راه حل آسانتری جهت سرد
                                    سازی سیال استفاده میکند.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default AircoolerPage;