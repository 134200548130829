import React from 'react';
import { HomeIcon } from '@heroicons/react/20/solid'
import Banner from "../../assetes/images/Aircooler/Gallery1.png";




const pages = [
    { name: 'مجله ما', href: '/News', current: false },
    { name: 'ایرکولر', href: '/MakhazenArticle', current: false },
]

const MakhazenArticle = () => {
    return (
        <div>
            {/* Breadcrumb */}
            <nav className="flex border-b border-gray-200 mt-16 bg-white" aria-label="Breadcrumb">
                <ol role="list" className="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8">
                    <li className="flex">
                        <div className="flex items-center">
                            <a href="/" className="text-gray-400 hover:text-gray-500">
                                <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true"/>
                                <span className="sr-only">Home</span>
                            </a>
                        </div>
                    </li>
                    {pages.map((page) => (
                        <li key={page.name} className="flex">
                            <div className="flex items-center">
                                <svg
                                    className="h-full w-6 flex-shrink-0 text-gray-200 transform rotate-180"
                                    viewBox="0 0 24 44"
                                    preserveAspectRatio="none"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z"/>
                                </svg>
                                <a
                                    href={page.href}
                                    className="mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                                    aria-current={page.current ? 'page' : undefined}
                                >
                                    {page.name}
                                </a>
                            </div>
                        </li>
                    ))}
                </ol>
            </nav>

            {/*Article */}
            <div className="bg-white" style={{backgroundImage: "url(" + Banner + ")"}}>
                <div className="px-6 py-24 z-0 sm:px-6 sm:py-32 lg:px-8">
                    <div className="mx-auto max-w-2xl text-center">
                        <p className="mx-auto opacity-0 mt-6 max-w-xl text-lg leading-8 text-gray-600">
                            Incididunt sint fugiat pariatur cupidatat consectetur sit cillum anim id veniam aliqua
                            proident excepteur
                            commodo do ea.
                        </p>
                        <div className="mt-10 opacity-0 flex items-center justify-center gap-x-6">
                            <a
                                href="#"
                                className="rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                شروع کنیم
                            </a>

                        </div>
                    </div>
                </div>
            </div>

            <div className="flow-root bg-white pb-10 sm:pb-18">
                <div className="-mt-9">
                    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                        <div
                            className="mx-auto flex max-w-2xl shadow-xl flex-col gap-16 bg-gray-200 bg-opacity-65 px-6 py-16 ring-2 ring-gray-900/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">

                            <div className="w-full flex-auto">
                                <h2 className="text-3xl font-bold tracking-tight text-blue-950 sm:text-4xl animate-fade-left animate-once">
                                    درباره ایرکولر</h2>
                                <p className="mt-6 text-lg leading-8 text-gray-900 animate-fade-up text-justify animate-once">
                                    ایر کولر صنعتی یا مبدل هوا خنک در اکثر پالایشگاه‌ها و پتروشیمی ها کارایی دارد که
                                    وظیفه اصلی ایر کولر کاهش دمای سیالات است.
                                    کولر هوایی نوعی مبدل حرارتی محسوب شده و توسط آن گرمای اضافی سیال به هوا انتقال
                                    می‌یابد تا هوای محیط درحد استاندارد کاهش پیدا کند.
                                    سیستم کاری ایر کولر صنعتی طبق تبادل گرمایشی طراحی می‌گردد و دمای آبگرم درون کویل را
                                    تا نزدیک دمای محیط کم می‌نماید.
                                    کولرهای هوایی برای مناطقی که بحران کمبود آب دارند یا بر اساس حداکثر دمای فضای مدنظر
                                    میتوان به دمای خروجی فرآیند تعیین شده دست یافت بکار برده می‌شوند.
                                    همچنین نحوه عملکرد ایر کولر با برج خنک کننده نیز تقریبا شباهت داشته ولیکن از راه حل
                                    آسانتری جهت سرد سازی سیال استفاده میکند.

                                </p>
                                <p className="mt-6 text-lg leading-8 text-gray-900 animate-fade-up text-justify animate-once">
                                    ایرکولر یک تجهیزات حرارتی و تهویه مطبوع صنعتی است که در بخش‌های صنعتی از جمله
                                    پالایشگاه‌ها، پتروشیمی‌ها، نیروگاه‌ها و سایر صنایع استفاده می‌شود. این تجهیزات به
                                    منظور کاهش دمای سیالات مورد استفاده قرار می‌گیرند. هدف اصلی ایرکولر، انتقال گرمای
                                    اضافی سیال به هوا است تا دمای سیال کاهش یابد و روند کارایی فرآیندها بهبود یابد.
                                    ایرکولر یک نوع مبدل حرارتی است که با استفاده از آب یا سایر سیالات گرم، هوای محیط را
                                    خنک می‌کند. در این سیستم، ابتدا سیال گرم به داخل کویل‌های ایرکولر وارد می‌شود و سپس
                                    هوا از روی این کویل‌ها عبور کرده و گرمای سیال به هوا منتقل می‌شود، در نتیجه هوای
                                    خروجی از ایرکولر خنکتر از هوای ورودی خواهد بود.
                                    از آنجایی که ایرکولر از آب یا سایر سیالات به عنوان منبع گرما استفاده می‌کند، برای
                                    مناطقی که با بحران کمبود آب روبرو هستند، راهکاری مناسب برای خنک کردن سیالات است.
                                    همچنین ایرکولر به عنوان یک روش آسانتر برای خنک کردن نسبت به برج‌های خنک‌کننده شناخته
                                    شده است.
                                    با توجه به اهمیت کارایی و عملکرد صحیح ایرکولر در صنایع مختلف، طراحی و استفاده صحیح
                                    از این تجهیزات امری حیاتی است. به طور کلی، ایرکولر یک تجهیزات مهم و ضروری برای
                                    صنایعی است که نیاز به خنک کردن سیالات خود دارند و نقش مهمی در بهبود کارایی فرآیندها
                                    و کاهش هزینه‌ها دارند.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default MakhazenArticle;