import React, { useState } from 'react';
import {HomeIcon} from "@heroicons/react/20/solid";
import Img1 from "../assetes/images/Makhazen/Makhazen5.png";
import Img2 from "../assetes/images/Mobadel/Gallery7.png";
import Img3 from "../assetes/images/Pressure/P2.png";
import Img4 from "../assetes/images/valve/V1.png";
import Img5 from "../assetes/images/Aircooler/Gallery3.png";
import Img6 from "../assetes/images/Industrial/4.png";


const pages = [
    { name: 'محصولات و خدمات', href: '/products', current: false },
]

const ProductsPage = ({ data = [], itemsPerPage = 10 }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    const handleNextPage = () => {
        if (currentPage < data.length / itemsPerPage) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div>
            {/* Breadcrumb */}
            <nav className="flex border-b border-gray-200 mt-16 bg-white" aria-label="Breadcrumb">
                <ol role="list" className="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8">
                    <li className="flex">
                        <div className="flex items-center">
                            <a href="/" className="text-gray-400 hover:text-gray-500">
                                <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                                <span className="sr-only">Home</span>
                            </a>
                        </div>
                    </li>
                    {pages.map((page) => (
                        <li key={page.name} className="flex">
                            <div className="flex items-center">
                                <svg
                                    className="h-full w-6 flex-shrink-0 text-gray-200 transform rotate-180"
                                    viewBox="0 0 24 44"
                                    preserveAspectRatio="none"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                                </svg>
                                <a
                                    href={page.href}
                                    className="mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                                    aria-current={page.current ? 'page' : undefined}
                                >
                                    {page.name}
                                </a>
                            </div>
                        </li>
                    ))}
                </ol>
            </nav>

            {/* Products */}
            <div className="bg-blue-100">
                <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl">
                    <div className="relative overflow-hidden rounded-lg lg:h-96">
                        <div className="absolute inset-0">
                            <img
                                src={Img1}
                                alt="Makhazen"
                                className="h-full w-full object-cover object-center"
                            />
                        </div>
                        <div aria-hidden="true" className="relative h-96 w-full lg:hidden" />
                        <div aria-hidden="true" className="relative h-32 w-full lg:hidden" />
                        <div className="absolute inset-x-0 bottom-0 rounded-bl-lg rounded-br-lg bg-black bg-opacity-75 p-6 backdrop-blur backdrop-filter sm:flex sm:items-center sm:justify-between lg:inset-x-auto lg:inset-y-0 lg:w-96 lg:flex-col lg:items-start lg:rounded-br-none lg:rounded-tl-lg">
                            <div>
                                <h2 className="text-xl font-bold text-white">مخازن ذخیره</h2>
                                <p className="mt-1 text-sm text-gray-300">
                                    مخازن پالایشگاهی (Oil and gas storage tanks)، این مخازن برای ذخیره سازی مواد نفتی و یا شیمیایی که در دمای محیط به صورت مایع هستند مورد استفاده قرار می‌گیرند.
                                </p>
                            </div>
                            <a
                                href="/MakhazenPage"
                                className="mt-6 flex flex-shrink-0 items-center justify-center rounded-md border border-white border-opacity-25 bg-white bg-opacity-0 px-4 py-3 text-base font-medium text-white hover:bg-opacity-10 sm:ml-8 sm:mt-0 lg:ml-0 lg:w-full"
                            >
                                مشاهده
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-orange-100">
                <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl">
                    <div className="relative overflow-hidden rounded-lg lg:h-96">
                        <div className="absolute inset-0">
                            <img
                                src={Img2}
                                alt="Mobadel"
                                className="h-full w-full object-cover object-center"
                            />
                        </div>
                        <div aria-hidden="true" className="relative h-96 w-full lg:hidden" />
                        <div aria-hidden="true" className="relative h-32 w-full lg:hidden" />
                        <div className="absolute inset-x-0 bottom-0 rounded-bl-lg rounded-br-lg bg-black bg-opacity-75 p-6 backdrop-blur backdrop-filter sm:flex sm:items-center sm:justify-between lg:inset-x-auto lg:inset-y-0 lg:w-96 lg:flex-col lg:items-start lg:rounded-br-none lg:rounded-tl-lg">
                            <div>
                                <h2 className="text-xl font-bold text-white">مبدل حرارتی</h2>
                                <p className="mt-1 text-sm text-gray-300">
                                    مبدل حرارتی لوله و پوسته (shell and tube)، مبدل های حرارتی پوسته و لوله یکی از پرکاربردترین انواع مبدل های حرارتی در صنایع مختلف هستند.
                                </p>
                            </div>
                            <a
                                href="/MobadelPage"
                                className="mt-6 flex flex-shrink-0 items-center justify-center rounded-md border border-white border-opacity-25 bg-white bg-opacity-0 px-4 py-3 text-base font-medium text-white hover:bg-opacity-10 sm:ml-8 sm:mt-0 lg:ml-0 lg:w-full"
                            >
                                مشاهده
                            </a>
                        </div>
                    </div>
                </div>
            </div>


            <div className="bg-blue-100">
                <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl">
                    <div className="relative overflow-hidden rounded-lg lg:h-96">
                        <div className="absolute inset-0">
                            <img
                                src={Img3}
                                alt="Pressure"
                                className="h-full w-full object-cover object-center"
                            />
                        </div>
                        <div aria-hidden="true" className="relative h-96 w-full lg:hidden" />
                        <div aria-hidden="true" className="relative h-32 w-full lg:hidden" />
                        <div className="absolute inset-x-0 bottom-0 rounded-bl-lg rounded-br-lg bg-black bg-opacity-75 p-6 backdrop-blur backdrop-filter sm:flex sm:items-center sm:justify-between lg:inset-x-auto lg:inset-y-0 lg:w-96 lg:flex-col lg:items-start lg:rounded-br-none lg:rounded-tl-lg">
                            <div>
                                <h2 className="text-xl font-bold text-white">مخازن تحت فشار</h2>
                                <p className="mt-1 text-sm text-gray-300">
                                    مخازن تحت فشار (pressure vessel)، مخازن تحت فشار به مخازنی گفته می‌شود که غالبا استوانه ای شکل ساخته می‌شوند و برای نگهداری مایعات یا گازها در فشاری غیر از فشار اتمسفر (تحت فشار) استفاده می‌شوند و بیشتر در صنایع نفت و گاز مورد استفاده قرار می‌گیرند.
                                </p>
                            </div>
                            <a
                                href="/PressurePage"
                                className="mt-6 flex flex-shrink-0 items-center justify-center rounded-md border border-white border-opacity-25 bg-white bg-opacity-0 px-4 py-3 text-base font-medium text-white hover:bg-opacity-10 sm:ml-8 sm:mt-0 lg:ml-0 lg:w-full"
                            >
                                مشاهده
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-orange-100">
                <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl">
                    <div className="relative overflow-hidden rounded-lg lg:h-96">
                        <div className="absolute inset-0">
                            <img
                                src={Img4}
                                alt="Valve"
                                className="h-full w-full object-cover object-center"
                            />
                        </div>
                        <div aria-hidden="true" className="relative h-96 w-full lg:hidden" />
                        <div aria-hidden="true" className="relative h-32 w-full lg:hidden" />
                        <div className="absolute inset-x-0 bottom-0 rounded-bl-lg rounded-br-lg bg-black bg-opacity-75 p-6 backdrop-blur backdrop-filter sm:flex sm:items-center sm:justify-between lg:inset-x-auto lg:inset-y-0 lg:w-96 lg:flex-col lg:items-start lg:rounded-br-none lg:rounded-tl-lg">
                            <div>
                                <h2 className="text-xl font-bold text-white">نوسازی و تعمیرات ولوها</h2>
                                <p className="mt-1 text-sm text-gray-300">
                                    شرکت استیل آروین تاو با بهره گیری از کادر فنی و مهندسی مجرب و ماشین آلات CNCدر زمینه ساخت، تعمیرات و نوسازی انواع شیر آلات صنعتی (Ball Valve, Check Valve, Globe Valve, Control valve, …)و همچنین در زمینه طراحی، ساخت و مهندسی معکوس قطعات دقیق و حساس صنعتی به کمک نرم افزارهای تخصصی CAD/CAM/CAE فعایت دارد و توانسته است در این زمینه نیز رزومه و سابقه درخشانی ایجاد کند که به اختصار عناوین و تصاویر تعدادی از پروژه ها و نمونه کارها ارائه میگردد.                                </p>
                            </div>
                            <a
                                href="/ValvePage"
                                className="mt-6 flex flex-shrink-0 items-center justify-center rounded-md border border-white border-opacity-25 bg-white bg-opacity-0 px-4 py-3 text-base font-medium text-white hover:bg-opacity-10 sm:ml-8 sm:mt-0 lg:ml-0 lg:w-full"
                            >
                                مشاهده
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-blue-100">
                <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl">
                    <div className="relative overflow-hidden rounded-lg lg:h-96">
                        <div className="absolute inset-0">
                            <img
                                src={Img5}
                                alt="AirCooler"
                                className="h-full w-full object-cover object-center"
                            />
                        </div>
                        <div aria-hidden="true" className="relative h-96 w-full lg:hidden" />
                        <div aria-hidden="true" className="relative h-32 w-full lg:hidden" />
                        <div className="absolute inset-x-0 bottom-0 rounded-bl-lg rounded-br-lg bg-black bg-opacity-75 p-6 backdrop-blur backdrop-filter sm:flex sm:items-center sm:justify-between lg:inset-x-auto lg:inset-y-0 lg:w-96 lg:flex-col lg:items-start lg:rounded-br-none lg:rounded-tl-lg">
                            <div>
                                <h2 className="text-xl font-bold text-white">ایرکولر</h2>
                                <p className="mt-1 text-sm text-gray-300">
                                    مبدل حرارتی هواخنک (Air cooler)، ایر کولر صنعتی یا مبدل هوا خنک در اکثر پالایشگاه‌ها و پتروشیمی ها کارایی دارد که وظیفه اصلی ایر کولر کاهش دمای سیالات است. کولر هوایی نوعی مبدل حرارتی محسوب شده و توسط آن گرمای اضافی سیال به هوا انتقال می‌یابد تا هوای محیط درحد استاندارد کاهش پیدا کند.
                                </p>
                            </div>
                            <a
                                href="/AircoolerPage"
                                className="mt-6 flex flex-shrink-0 items-center justify-center rounded-md border border-white border-opacity-25 bg-white bg-opacity-0 px-4 py-3 text-base font-medium text-white hover:bg-opacity-10 sm:ml-8 sm:mt-0 lg:ml-0 lg:w-full"
                            >
                               مشاهده
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-orange-100">
                <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl">
                    <div className="relative overflow-hidden rounded-lg lg:h-96">
                        <div className="absolute inset-0">
                            <img
                                src={Img6}
                                alt="Industry"
                                className="h-full w-full object-cover object-center"
                            />
                        </div>
                        <div aria-hidden="true" className="relative h-96 w-full lg:hidden" />
                        <div aria-hidden="true" className="relative h-32 w-full lg:hidden" />
                        <div className="absolute inset-x-0 bottom-0 rounded-bl-lg rounded-br-lg bg-black bg-opacity-75 p-6 backdrop-blur backdrop-filter sm:flex sm:items-center sm:justify-between lg:inset-x-auto lg:inset-y-0 lg:w-96 lg:flex-col lg:items-start lg:rounded-br-none lg:rounded-tl-lg">
                            <div>
                                <h2 className="text-xl font-bold text-white">قطعات صنعتی</h2>
                                <p className="mt-1 text-sm text-gray-300">
                                    ساخت قطعات صنعتی
                                </p>
                            </div>
                            <a
                                href="/IndustryPage"
                                className="mt-6 flex flex-shrink-0 items-center justify-center rounded-md border border-white border-opacity-25 bg-white bg-opacity-0 px-4 py-3 text-base font-medium text-white hover:bg-opacity-10 sm:ml-8 sm:mt-0 lg:ml-0 lg:w-full"
                            >
                                مشاهده
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            

        </div>
    );
};

export default ProductsPage;