import React, {useState} from 'react';
import { HomeIcon } from '@heroicons/react/20/solid'
import {BsChevronCompactLeft, BsChevronCompactRight} from "react-icons/bs";
import {RxDotFilled} from "react-icons/rx";
import Img1 from "../../assetes/images/Industrial/4.png";
import Img2 from "../../assetes/images/Industrial/Gallery4.png";
import Img3 from "../../assetes/images/Industrial/Gallery2.png";
import Img4 from "../../assetes/images/Industrial/Gallery6.png";





const pages = [
    { name: 'محصولات', href: '/products', current: false },
    { name: 'قطعات صنعتی', href: '/IndustryPage', current: false },
]

const IndustryPage = () => {

    const slides = [
        {
            img: Img1,
        },
        {
            img: Img2,
        },
        {
            img: Img3,
        },
        {
            img: Img4,
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    const prevSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const nextSlide = () => {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
    };

    return (
        <div>
            {/* Breadcrumb */}
            <nav className="flex border-b border-gray-200 mt-16 bg-white" aria-label="Breadcrumb">
                <ol role="list" className="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8">
                    <li className="flex">
                        <div className="flex items-center">
                            <a href="/" className="text-gray-400 hover:text-gray-500">
                                <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true"/>
                                <span className="sr-only">Home</span>
                            </a>
                        </div>
                    </li>
                    {pages.map((page) => (
                        <li key={page.name} className="flex">
                            <div className="flex items-center">
                                <svg
                                    className="h-full w-6 flex-shrink-0 text-gray-200 transform rotate-180"
                                    viewBox="0 0 24 44"
                                    preserveAspectRatio="none"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z"/>
                                </svg>
                                <a
                                    href={page.href}
                                    className="mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                                    aria-current={page.current ? 'page' : undefined}
                                >
                                    {page.name}
                                </a>
                            </div>
                        </li>
                    ))}
                </ol>
            </nav>

            {/*Article */}
            <div className='max-w-[1000px] h-[600px] w-full m-auto py-16 px-4 relative group'>
                <div
                    style={{backgroundImage: `url(${slides[currentIndex].img})`}}
                    className='w-full h-full rounded-2xl bg-center bg-cover duration-500'
                ></div>
                {/* Left Arrow */}
                <div
                    className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
                    <BsChevronCompactLeft onClick={prevSlide} size={30}/>
                </div>
                {/* Right Arrow */}
                <div
                    className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
                    <BsChevronCompactRight onClick={nextSlide} size={30}/>
                </div>
                <div className='flex top-4 justify-center py-2'>
                    {slides.map((slide, slideIndex) => (
                        <div
                            key={slideIndex}
                            onClick={() => goToSlide(slideIndex)}
                            className='text-2xl cursor-pointer'
                        >
                            <RxDotFilled/>
                        </div>
                    ))}
                </div>
            </div>


            <div className="flow-root bg-white pb-10 sm:pb-18">
                <div className="">
                    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                        <div
                            className="mx-auto flex max-w-2xl shadow-xl flex-col gap-16 bg-gray-200 bg-opacity-65 px-6 py-16 ring-2 ring-gray-900/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">

                            <div className="mx-auto max-w-3xl text-center">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">قطعات
                                    صنعتی</h2>
                                <p className="mt-4 text-gray-500">
                                    ساخت قطعات صنعتی
                                </p>
                                <br/>
                                <p className="text-right text-justify">
                                    قطعات صنعتی از اهمیت بالایی در صنایع مختلف و تولید محصولات استفاده می‌شود. این قطعات
                                    باعث بهبود عملکرد، کارایی و ایمنی در فرآیندهای تولیدی می‌شوند. در ادامه، به بررسی
                                    برخی از نکات مهم و اهمیت قطعات صنعتی خواهیم پرداخت.
                                    یکی از اهمیت‌های قطعات صنعتی این است که این قطعات به طور مستقیم در تولید محصولات
                                    نهایی تأثیر گذارند. قطعات صنعتی مانند واشرها، پیچ‌ها، پولی‌ها، و تورمپون‌ها ممکن است
                                    به نظر چیزهای کوچک و جزئی بیایند، اما عدم صحت یا کیفیت پایین این قطعات می‌تواند به
                                    مشکلات جدی در تولید و تحویل محصولات منتهی شود.
                                    قطعات صنعتی باعث بهبود عملکرد و کارایی دستگاه‌ها و تجهیزات صنعتی می‌شوند. هر قطعه
                                    کوچکی که در یک ماشین یا تجهیزات صنعتی استفاده می‌شود، تأثیر مستقیم بر عملکرد کلی آن
                                    دستگاه دارد. قطعات با کیفیت و مطابق با استانداردهای لازم، باعث افزایش کارایی و کاهش
                                    خطرات ناشی از خرابی و عمرکوتاهی تجهیزات می‌شوند.
                                    همچنین، هدف اصلی قطعات صنعتی ایجاد ایمنی در محیط کار است. قطعات با کیفیت و ایمن،
                                    می‌توانند خطرات ناشی از حوادث صنعتی و تأمین ایمنی کارگران را کاهش دهند. برای مثال،
                                    استفاده از قطعات فولادی مستحکم و مطابق با استانداردهای ایمنی می‌تواند از خطرات سقوط
                                    و خرابی دستگاه‌ها جلوگیری کند.
                                    به طور کلی، قطعات صنعتی نقش بسیار حیاتی و حیاتی در صنایع مختلف دارند. انتخاب و
                                    استفاده از قطعات با کیفیت و استاندارد منجر به بهبود عملکرد کلی فرآیندهای تولیدی و
                                    ایمنی در محیط کار می‌شود. بنابراین، توجه به انتخاب و استفاده از قطعات صنعتی صحیح و
                                    با کیفیت، امری ضروری و حیاتی برای هر صنعت و کارخانه است.

                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default IndustryPage;