import React from 'react';
import {CheckCircleIcon, HomeIcon} from '@heroicons/react/20/solid'
import Banner from "../../assetes/images/Makhazen/Makhazen5.png";



const pages = [
    { name: 'مجله ما', href: '/News', current: false },
    { name: 'مخازن ذخیره', href: '/MakhazenArticle', current: false },
]

const MakhazenArticle = () => {
    return (
        <div>
            {/* Breadcrumb */}
            <nav className="flex border-b border-gray-200 mt-16 bg-white" aria-label="Breadcrumb">
                <ol role="list" className="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8">
                    <li className="flex">
                        <div className="flex items-center">
                            <a href="/" className="text-gray-400 hover:text-gray-500">
                                <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true"/>
                                <span className="sr-only">Home</span>
                            </a>
                        </div>
                    </li>
                    {pages.map((page) => (
                        <li key={page.name} className="flex">
                            <div className="flex items-center">
                                <svg
                                    className="h-full w-6 flex-shrink-0 text-gray-200 transform rotate-180"
                                    viewBox="0 0 24 44"
                                    preserveAspectRatio="none"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z"/>
                                </svg>
                                <a
                                    href={page.href}
                                    className="mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                                    aria-current={page.current ? 'page' : undefined}
                                >
                                    {page.name}
                                </a>
                            </div>
                        </li>
                    ))}
                </ol>
            </nav>

            {/*Article */}
            <div className="bg-white" style={{backgroundImage: "url(" + Banner + ")"}}>
                <div className="px-6 py-24 z-0 sm:px-6 sm:py-32 lg:px-8">
                    <div className="mx-auto max-w-2xl text-center">
                        <p className="mx-auto opacity-0 mt-6 max-w-xl text-lg leading-8 text-gray-600">
                            Incididunt sint fugiat pariatur cupidatat consectetur sit cillum anim id veniam aliqua
                            proident excepteur
                            commodo do ea.
                        </p>
                        <div className="mt-10 opacity-0 flex items-center justify-center gap-x-6">
                            <a
                                href="#"
                                className="rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                شروع کنیم
                            </a>

                        </div>
                    </div>
                </div>
            </div>

            <div className="flow-root bg-white pb-10 sm:pb-18">
                <div className="-mt-9">
                    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                        <div
                            className="mx-auto flex max-w-2xl shadow-xl flex-col gap-16 bg-gray-200 bg-opacity-65 px-6 py-16 ring-2 ring-gray-900/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">

                            <div className="w-full flex-auto">
                                <h2 className="text-3xl font-bold tracking-tight text-blue-950 sm:text-4xl animate-fade-left animate-once">درباره
                                    مخارن ذخیره</h2>
                                <p className="mt-6 text-lg leading-8 text-gray-900 animate-fade-up text-justify animate-once">
                                    این مخازن برای ذخیره سازی مواد نفتی و یا شیمیایی که در دمای محیط به صورت مایع هستند
                                    مورد استفاده قرار می‌گیرند.
                                    موادی همچون نفت خام، بنزین، گازوییل و برش های سنگین نفت خام در این مخازن ذخیره سازی
                                    می‌شوند.
                                    این مخازن در ابعاد و اندازه های مختلف و بسته به نوع ماده ذخیره شونده دارای سقف های
                                    متفاوت می‌باشند.
                                    از مخازن ذخیره می‌توان به مخازن با سقف ثابت، مخازن سقف شناور، مخازن کروی، استوانه ای
                                    و مخازن سرد اشاره نمود.
                                    از آنجا که مواد مختلف دارای خواص شیمیایی و فیزیکی مختلفی هستند، شرایط و نحوه مناسب
                                    ذخیره سازی‌ آنها از یکدیگر متفاوت است.
                                    به همین جهت انتخاب نوع مناسب اهمیت فراوانی دارد. مهمترین پارامترها در انتخاب نوع
                                    مخزن :
                                </p>
                                <ul role="list" className="mt-8 max-w-xl space-y-8 text-gray-600">
                                    <li className="flex gap-x-3">
                                        <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-blue-900"
                                                         aria-hidden="true"/>
                                        <span>
                  فراریت یا به عبارت دیگر فشار بخار
                </span>
                                    </li>
                                    <li className="flex gap-x-3">
                                        <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-blue-900"
                                                         aria-hidden="true"/>
                                        <span>
                  سمی بودن
                </span>
                                    </li>
                                    <li className="flex gap-x-3">
                                        <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-blue-900"
                                                         aria-hidden="true"/>
                                        <span>
                  میزان آتش گیری ماده مورد نظر
                </span>
                                    </li>
                                </ul>
                                <p className="mt-6 text-lg leading-8 text-gray-900 animate-fade-up text-justify animate-once">
                                    تقسیم بندی مرسوم مخازن ذخیره ای براساس نوع سقف آنها بصورت سقف ثابت Cone یا Dome و یا
                                    سقف شناور به صورت Internal Floating یا Floating Roof می‌باشند.
                                    <br/>
                                    در صورتی که فشار بخار ماده مورد نظر زیاد نباشد ولی, ماده مورد نظر, سمی یا آتش گیر
                                    باشد, مانند ترکیبات سنگین نفتی, اکریل آمید, دی اتیل پیرو کربنات, دی ایزوپیل
                                    فلوئوروفسفات و ... از مخازن با سقف ثابت استفاده می‌گردد.
                                    <br/>
                                    موادی چون نفت خام که فشار بخار آنها, کمی زیاد بوده و در حدود نزدیک به psi5/0 است, در
                                    مخازن خاصی که مجهز به سقف شناور می‌باشند, ذخیره می‌گردند. این نوع از مخازن, فاقد سقف
                                    ثابت بوده و به اصطلاح External Floating Roof گفته می‌شوند. گفتنی است که این مخازن,
                                    برای موادی که سمی نبوده یا آتش گیری کمی دارند, مناسب می‌باشند.
                                    <br/>
                                    اگر فشار بخار ماده ای در همین محدوده بوده ولی, ماده مذکور سمی یا آتش گیر باشد, از
                                    نوع خاصی از مخازن با سقف شناور که دارای یک سقف نیز می‌باشند, استفاده می‌گردد. این
                                    نوع مخازن در اصطلاح, Internal Floating Roof گفته می‌شوند.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default MakhazenArticle;