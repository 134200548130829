import React from 'react';
import { HomeIcon} from '@heroicons/react/20/solid'
import Banner from "../../assetes/images/Mobadel/Gallery7.png";




const pages = [
    { name: 'مجله ما', href: '/News', current: false },
    { name: 'مبدل حرارتی', href: '/MakhazenArticle', current: false },
]

const MakhazenArticle = () => {
    return (
        <div>
            {/* Breadcrumb */}
            <nav className="flex border-b border-gray-200 mt-16 bg-white" aria-label="Breadcrumb">
                <ol role="list" className="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8">
                    <li className="flex">
                        <div className="flex items-center">
                            <a href="/" className="text-gray-400 hover:text-gray-500">
                                <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true"/>
                                <span className="sr-only">Home</span>
                            </a>
                        </div>
                    </li>
                    {pages.map((page) => (
                        <li key={page.name} className="flex">
                            <div className="flex items-center">
                                <svg
                                    className="h-full w-6 flex-shrink-0 text-gray-200 transform rotate-180"
                                    viewBox="0 0 24 44"
                                    preserveAspectRatio="none"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z"/>
                                </svg>
                                <a
                                    href={page.href}
                                    className="mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                                    aria-current={page.current ? 'page' : undefined}
                                >
                                    {page.name}
                                </a>
                            </div>
                        </li>
                    ))}
                </ol>
            </nav>

            {/*Article */}
            <div className="bg-white" style={{backgroundImage: "url(" + Banner + ")"}}>
                <div className="px-6 py-24 z-0 sm:px-6 sm:py-32 lg:px-8">
                    <div className="mx-auto max-w-2xl text-center">
                        <p className="mx-auto opacity-0 mt-6 max-w-xl text-lg leading-8 text-gray-600">
                            Incididunt sint fugiat pariatur cupidatat consectetur sit cillum anim id veniam aliqua
                            proident excepteur
                            commodo do ea.
                        </p>
                        <div className="mt-10 opacity-0 flex items-center justify-center gap-x-6">
                            <a
                                href="#"
                                className="rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                شروع کنیم
                            </a>

                        </div>
                    </div>
                </div>
            </div>

            <div className="flow-root bg-white pb-10 sm:pb-18">
                <div className="-mt-9">
                    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                        <div
                            className="mx-auto flex max-w-2xl shadow-xl flex-col gap-16 bg-gray-200 bg-opacity-65 px-6 py-16 ring-2 ring-gray-900/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">

                            <div className="w-full flex-auto">
                                <h2 className="text-3xl font-bold tracking-tight text-blue-950 sm:text-4xl animate-fade-left animate-once">
                                    درباره مبدل حرارتی</h2>
                                <p className="mt-6 text-lg leading-8 text-gray-900 animate-fade-up text-justify animate-once">
                                    مبدل های حرارتی پوسته و لوله یکی از پرکاربردترین انواع مبدل های حرارتی در صنایع
                                    مختلف هستند.
                                    این مبدل ها از یک مجموعه لوله ها و یک پوسته تشکیل شده اند.
                                    مبدل حرارتی پوسته و لوله مناسب برای کاربردهایی است که دارای فشار و دمای بالا هستند.
                                    این مبدل بیشترین کاربرد را در صنایع مختلف داشته و از دو جز اصلی ساخته شده است.
                                    بطور کلی ساختار تشکیل دهنده این مبدل دسته ای از لوله ها(تیوب باندل) است که درون یک
                                    پوسته قرار گرفته اند.
                                    بدین ترتیب یک سیال درون لوله ها جاری می‌شود و یک سیال نیز درون پوسته جریان پیدا
                                    می‌کند.
                                    در نتیجه حرارت از یک سیال به سیالی دیگر، به وسیله دیواره لوله ها منتقل می‌شود.

                                </p>
                                <p className="mt-6 text-lg leading-8 text-gray-900 animate-fade-up text-justify animate-once">
                                    مبدل حرارتی پوسته و لوله به دلیل ساختار پیچیده و مقاومت بالا، برای کاربردهایی که
                                    نیاز به انتقال حرارت در شرایط سخت دارند، مناسب است. این مبدل ها معمولا در صنایع نفت
                                    و گاز، پتروشیمی، آب و فاضلاب و صنایع استخراج معدن استفاده می‌شوند.
                                    ابعاد و مشخصات مبدل حرارتی پوسته و لوله بستگی به نیاز و توانایی انتقال حرارت در هر
                                    کاربردهای دارند. انتخاب مناسب اندازه و جنس مواد ساخته شده آن، از اهمیت بالایی
                                    برخوردار است. همچنین نظارت و نگهداری دقیق برای افزایش عمر مفید این مبدل ها ضروری
                                    است.
                                    در مقاله حاضر، مفهوم، ساختار و کاربردهای مبدل حرارتی پوسته و لوله بررسی شده است. بر
                                    اساس اطلاعات موجود، مبدل حرارتی پوسته و لوله یکی از بهترین و پرکاربردترین انواع مبدل
                                    های حرارتی برای صنایع مختلف است. این مبدل ها به عنوان یک واسطه موثر برای انتقال
                                    حرارت در فرآیندهای مختلف شناخته شده اند و با توجه به ویژگی های منحصر به فرد خود، به
                                    بهره وری بالا در کاربردها منجر می‌شوند.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default MakhazenArticle;