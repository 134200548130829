import { Routes, Route } from 'react-router-dom';
import NavbarComponent from './components/ui/Navbar.component';
import FooterComponent from './components/ui/Footer.component';
import HomePage from './pages/Home.page';
import ProductsPage from './pages/Products.page';
import NewsPage from './pages/News.page';
import AboutUsPage from './pages/AboutUs.page';
import ContactUsPage from './pages/ContactUs.page';
import MakhazenArticle from './pages/Articles/Makhazen.article';
import MobadelArticle from './pages/Articles/Mobadel.article';
import AircoolerArticle from './pages/Articles/Aircooler.article';
import MakhazenPage from './pages/Products/Makhazen.page';
import IndustryPage from './pages/Products/Industry.page';
import AircoolerPage from './pages/Products/Aircooler.page';
import MobadelPage from './pages/Products/Mobadel.page';
import PressurePage from './pages/Products/Pressure.page';
import ValvePage from './pages/Products/Valve.page';
import NotFoundPage from './pages/NotFound.page';

const routes = [
    { path: '/', element: <HomePage /> },
    { path: '/products', element: <ProductsPage /> },
    { path: '/News', element: <NewsPage /> },
    { path: '/aboutus', element: <AboutUsPage /> },
    { path: '/contactus', element: <ContactUsPage /> },
    { path: '/MakhazenArticle', element: <MakhazenArticle /> },
    { path: '/MobadelArticle', element: <MobadelArticle /> },
    { path: '/AircoolerArticle', element: <AircoolerArticle /> },
    { path: '/MakhazenPage', element: <MakhazenPage /> },
    { path: '/IndustryPage', element: <IndustryPage /> },
    { path: '/AircoolerPage', element: <AircoolerPage /> },
    { path: '/MobadelPage', element: <MobadelPage /> },
    { path: '/PressurePage', element: <PressurePage /> },
    { path: '/ValvePage', element: <ValvePage /> },
    { path: '*', element: <NotFoundPage /> },
];

function App() {
    return (
        <Routes>
            {routes.map((route, index) => (
                <Route
                    key={index}
                    path={route.path}
                    element={
                        route.path === '*'
                            ? route.element
                            : (
                                <div>
                                    <NavbarComponent />
                                    <div className="wrapper">
                                        {route.element}
                                    </div>
                                    <FooterComponent />
                                </div>
                            )
                    }
                />
            ))}
        </Routes>
    );
}

export default App;