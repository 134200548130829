import React from 'react';
import { useState } from 'react'
import { SocialIcon } from 'react-social-icons'
import GoogleMapReact from 'google-map-react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import Cbanner from '../assetes/images/Cbanner.png'
import { HomeIcon } from '@heroicons/react/20/solid'
import ContactFormComponent from "../components/ContactForm.component";






const incentives = [

    {
        name: 'اینستاگرام',
        icons: <SocialIcon url="https://instagram.com" />,
    },

    {
        name: 'تلگرام',
        icons: <SocialIcon url="https://telegram.com" />,
    },
    {
        name: 'واتس‌اپ',
        icons: <SocialIcon url="https://whatsapp.com" />,
    },
    {
        name: 'یوتوپ',
        icons: <SocialIcon url="https://youtube.com" />,
    },
]

const defaultProps = {
    center: {
        lat: 59.95,
        lng: 30.33
    },
    zoom: 12
};

const AnyReactComponent = ({ text }) => (
    <div style={{ color: 'red', fontSize: '24px' }}>
        <FaMapMarkerAlt />
        {text}
    </div>
);

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const pages = [
    { name: 'ارتباط با ما', href: '/contactus', current: false },
]


const ContactUsPage = () => {

    const [agreed, setAgreed] = useState(false)


    return (
        <div>

            {/* Breadcrumb */}
            <nav className="flex border-b border-gray-200 mt-16 bg-white" aria-label="Breadcrumb">
                <ol role="list" className="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8">
                    <li className="flex">
                        <div className="flex items-center">
                            <a href="/" className="text-gray-400 hover:text-gray-500">
                                <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                                <span className="sr-only">Home</span>
                            </a>
                        </div>
                    </li>
                    {pages.map((page) => (
                        <li key={page.name} className="flex">
                            <div className="flex items-center">
                                <svg
                                    className="h-full w-6 flex-shrink-0 text-gray-200 transform rotate-180"
                                    viewBox="0 0 24 44"
                                    preserveAspectRatio="none"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                                </svg>
                                <a
                                    href={page.href}
                                    className="mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                                    aria-current={page.current ? 'page' : undefined}
                                >
                                    {page.name}
                                </a>
                            </div>
                        </li>
                    ))}
                </ol>
            </nav>



            <div className="bg-white mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-3xl">
                    <img src={Cbanner} />
                </div>
            </div>

            {/*Contacts Path */}



            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900">تماس با گروه صنعتی استیل‌ آروین‌تاو</h2>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            کوتاه‌ترین مسیرها برای ارتباط و گفتگو با ما
                        </p>
                    </div>
                    <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4">
                        <div>
                            <h3 className="border-l border-indigo-600 pl-6 font-semibold text-gray-900">پشتیبانی فروش</h3>
                            <address className="border-l border-gray-200 pl-6 pt-2 not-italic text-gray-600">
                                <p>شماره تماس: ۰۹۱۷۹۲۸۸۸۰۸</p>
                            </address>
                        </div>
                        <div>
                            <h3 className="border-l border-indigo-600 pl-6 font-semibold text-gray-900">پشتیبانی فنی</h3>
                            <address className="border-l border-gray-200 pl-6 pt-2 not-italic text-gray-600">
                                <p>شماره تماس: ۰۹۱۷۹۲۸۸۸۰۸</p>
                            </address>
                        </div>
                        <div>
                            <h3 className="border-l border-indigo-600 pl-6 font-semibold text-gray-900">ارسال فرم</h3>
                            <address className="border-l border-gray-200 pl-6 pt-2 not-italic text-gray-600">
                                <p>شما می‌توانید از طریق ارسال فرم با ما در ارتباط باشید.</p>

                            </address>
                        </div>
                        <div>
                            <h3 className="border-l border-indigo-600 pl-6 font-semibold text-gray-900">دفتر مرکزی</h3>
                            <address className="border-l border-gray-200 pl-6 pt-2 not-italic text-gray-600">
                                <p>شیراز، شهرک صنعتی بزرگ، بلوار نوآوران، خیابان ۵۰۱</p>
                            </address>
                        </div>
                    </div>
                </div>
            </div>


            {/* Google Map */}
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-3xl">
                    <div style={{ height: '50vh', width: '100%' }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "AIzaSyB7nN60IyfpbfKDs72wCg8FDZ4ngtR1PeU", language: 'fa' }}
                            defaultCenter={{ lat: 29.476916256424527, lng: 52.54243014209525 }}
                            defaultZoom={defaultProps.zoom}
                        >

                            <AnyReactComponent
                                lat={29.476916256424527}
                                lng={52.54243014209525}
                                text="استیل آروین‌تاو"
                            />
                        </GoogleMapReact>
                    </div>
                </div>
            </div>

            {/* Social Media */}
            <div className="bg-white">
                <div className="mx-auto max-w-7xl py-24 sm:px-2 sm:py-32 lg:px-4">
                    <div className="mx-auto max-w-2xl px-4 lg:max-w-none">
                        <div className="max-w-3xl">
                            <h2 className="text-4xl font-bold tracking-tight text-gray-900">
                                رسانه‌های ما
                            </h2>
                            <p className="mt-4 text-gray-500">
                                ما را در شبکه‌های اجتماعی دنبال کنید.
                            </p>
                        </div>
                        <div className="mt-16 grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-6">
                            {incentives.map((incentive) => (
                                <div key={incentive.name} className="sm:flex lg:block">
                                    <div className="sm:flex-shrink-0">
                                        {incentive.icons}
                                    </div>
                                    <div className="mt-4 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
                                        <h3 className="text-sm font-medium text-gray-900">{incentive.name}</h3>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>



            {/* Contact Form */}
            <ContactFormComponent />




        </div>
    );
};

export default ContactUsPage;